<template>
  <v-container grid-list-md justify-space-around="True" style="height: 100%;">
    <v-layout row wrap>
      <!--alert thresholds-->
      <v-flex xs12 sm12 md6>
        <v-card>
          <v-app-bar dense>
            <v-toolbar-title>
              Alert Thresholds
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip v-if="editThreshod" bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" v-if="editThreshod" @click.native="editThreshod = !editThreshod" slot="activator">
                        <v-icon small color="teal">far fa-edit</v-icon>
                    </v-btn>
                </template>
                    <span>Edit</span>
            </v-tooltip>
            <v-tooltip v-if="!editThreshod" bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" v-if="!editThreshod" @click.native="saveThreshod = !saveThreshod; editThreshod = !editThreshod; snackbar = true" slot="activator">
                        <v-icon small color="green">far fa-save</v-icon>
                    </v-btn>
                </template>
                    <span>Save</span>
            </v-tooltip>
          </v-app-bar>
          <v-card-text>
            <v-switch :disabled="editThreshod" v-model="alertThreshods" label="Enable Alert Thresholds"></v-switch>

            <v-list v-if="alertThreshods"
                    subheader
                    three-line>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title class="subheader">Send alert when balance drops below: </v-list-tile-title>
                  <v-list-tile-sub-title>
                    <v-flex xs12 sm12 md12 d-flex>
                      <v-text-field v-model="lowbalance"
                                    :disabled="editThreshod"
                                    color="red"
                                    prefix="$"></v-text-field>
                    </v-flex>
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title class="subheader">Send alert when transaction exceeds:  </v-list-tile-title>
                  <v-list-tile-sub-title>
                    <v-flex xs12 sm12 md12 d-flex>
                      <v-text-field v-model="transactionlimit"
                                    :disabled="editThreshod"
                                    color="red"
                                    prefix="$"></v-text-field>
                    </v-flex>
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>

          </v-card-text>

        </v-card>
      </v-flex>

      <v-snackbar v-model="snackbar"
                  left>
        Notifications Updated
        <v-btn dark
               flat
               @click="snackbar = false">
          Close
        </v-btn>
      </v-snackbar>


    </v-layout>
  </v-container>
</template>
<script>
  export default {
    data() {
      return {
        saveThreshod: false,
        editThreshod: true,
        alertThreshods: false,
        lowbalance: '500',
        transactionlimit: '200',
        snackbar: false,
      }
    }
  }
</script>
